<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="d-flex flex-wrap align-center justify-end">
      <portal to="pageTitle" :disabled="checkMd">
        <div class="font-page-header pr-5 pr-sm-15 mb-md-15">Login Security</div>
      </portal>

      <ActionOutlinedButton class="mr-5 mr-md-11 mb-4 mb-sm-15" @click="openDialogLogoutOfAll">
        Log out of all devices
      </ActionOutlinedButton>

      <RefreshButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :disabled="loading" @click="refresh" />

      <v-spacer />

      <SelectOnPage
        class="pagination-container paginationColor--text ml-5 ml-sm-8 mb-4 mb-sm-15"
        :readonly="loading"
        @changeOnPage="refresh"
      />
    </div>

    <div>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options="options"
        :custom-sort="() => items"
        class="elevation-0 borderNone tableBg rounded-20 borderTable"
        outlined
        disable-pagination
        hide-default-footer
        :loader-height="2"
        :header-props="{ sortIcon: '$sortDownIcon' }"
        :mobile-breakpoint="0"
        @update:options="changeOptions"
      >
        <template #no-data>
          <div class="d-flex">
            <span class="font-normal text-left">No data available</span>
          </div>
        </template>

        <template #item.date="{ item }">
          <span class="font-normal text-no-wrap">
            {{ formatDate(item['created_at']) }}
          </span>
        </template>
      </v-data-table>

      <TablePagination
        :loading="loading"
        :dataPagination="dataPagination"
        @input="changePage"
      />

      <div :class="dataPagination.lastPage > 1 ? 'pb-4' : 'pb-26'"></div>
    </div>

    <DialogConfirm
      v-model="confirmDialog"
      :loading="loadingAction"
      title="Log out of all devices"
      subTitle=""
      :confirmButtons="confirmButtons"
      message="You are going to be log out of all devices including the current one"
      @actionSuccess="goToLogoutOfAllDevices"
    />
  </v-container>
</template>

<script>
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import RefreshButton from '@/components/buttons/RefreshButton'
import SelectOnPage from '@/components/select/SelectOnPage'
import TablePagination from '@/components/pagination/TablePagination'
import DialogConfirm from '@/components/dialog/DialogConfirm'

import { getLoginSecurity } from '@/api/setting-routes'
import { getOnPage, parsePaginationData, formatCallLogDate } from '@/helper/app-helper'
import { getLogoutAll } from '@/api/auth-routes'

import { mapActions } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

export default {
  name: 'LoginSecurity',
  components: { ActionOutlinedButton, RefreshButton, SelectOnPage, TablePagination, DialogConfirm },
  data: () => ({
    loading: false,
    dialog: false,
    confirmDialog: false,
    loadingAction: false,
    items: [],
    dataPagination: {
      page: 1,
      lastPage: 1,
    },
    options: {},
    headers: [
      { text: 'Date', value: 'date', sortId: 'byDate' },
      { text: 'IP', value: 'ip', sortId: 'byIp' },
      { text: 'Device', value: 'device', sortId: 'byDevice' },
      { text: 'Description', value: 'description', sortId: 'byDescription' },
    ],
    serverSorting: null,
    dialogDescription: null,
    confirmButtons: ['Ok', 'Cancel']
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
  },
  methods: {
    ...mapActions({
      deleteAccountFromState: authTypes.actions.REMOVE_ACCOUNT_DATA,
    }),
    changeOptions(options) {
      if (!this.loading) {
        this.options = Object.assign({}, options)
      }
    },
    formatDate(date) {
      return formatCallLogDate(date)
    },
    async loadLoginSecurity() {
      this.loading = true

      const { success, payload, message } = await getLoginSecurity({
        page: this.dataPagination.page,
        onPage: getOnPage(),
        ...this.serverSorting,
      })

      if (success) {
        this.dataPagination = parsePaginationData(payload)
        this.items = payload.data
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    changePage(page) {
      if (page !== this.dataPagination.page) {
        this.dataPagination.page = page
        this.loadLoginSecurity()
      }
      this.$vuetify.goTo(0)
    },
    refresh() {
      this.dataPagination.page = 1
      this.loadLoginSecurity()
    },
    openDialogDescription(item) {
      this.dialogDescription = item?.description ? item.description : ''
      this.dialog = true
    },
    getType(type) {
      return !!type ? type.name : '-//-'
    },
    openDialogLogoutOfAll() {
      this.confirmDialog = true
    },
    async goToLogoutOfAllDevices() {
      this.loadingAction = true

      const { success, payload, message } = await getLogoutAll()

      if (success) {
        this.confirmDialog = false
        this.deleteAccountFromState()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
  },
  watch: {
    options: {
      deep: true,
      handler(sortOptions) {
        if (sortOptions.sortBy.length) {
          const sortBy = this.headers.find(item => item.value === sortOptions.sortBy[0])
          this.serverSorting = { [sortBy.sortId]: sortOptions.sortDesc[0] ? 'asc' : 'desc' }
        } else {
          this.serverSorting = null
        }
        this.loadLoginSecurity()
      },
    },
  },
}
</script>